Livewire.onLoad(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('login')) {
        if (urlParams.has('redirect_url')) {
            Livewire.emit('openSignInModal', urlParams.get('redirect_url'))
        } else {
            Livewire.emit('openSignInModal')
        }
    }
})

window.livewire.on('alert', param => {
    const message = param[0];
    const type = param[1];

    toastr.options = {
        "closeButton": true,
        "newestOnTop": true,
        "positionClass": "toast-bottom-right",
        "preventDuplicates": true,
        "showDuration": "5000",
        "hideDuration": "700",
    }

    toastr[type](message);
});

Livewire.on('openSignInModal', () => {
    $('.modal').modal('hide');
    $('#sign-in').modal();
})

Livewire.on('openSignUpModal', () => {
    $('.modal').modal('hide');
    $('#sign-up').modal();
})

Livewire.on('openForgotPasswordModal', () => {
    $('.modal').modal('hide');
    $('#forgot-password').modal();
})

Livewire.on('openContactAdModal', () => {
    $('.modal').modal('hide');
    $('#contact-ad').modal();
})

$('#sign-in').on('hidden.bs.modal', function (e) {
    Livewire.emit('signInModalClosed')
})

$('#sign-up').on('hidden.bs.modal', function (e) {
    Livewire.emit('signUpModalClosed')
})

$('#forgot-password').on('hidden.bs.modal', function (e) {
    Livewire.emit('forgotPasswordModalClosed')
})

$('#contact-ad').on('hidden.bs.modal', function (e) {
    Livewire.emit('contactAdModalClosed')
})